
/*
    Table of Contents
    1. General
    2. Navbar
    3. Home
    4. About
    5. Search
    6. Footer
    6. Activity Detail
    7. My Activities
    8. Activity Detail - Modal
    9. Create New Activity Form
    10. Authentication: Login and Sign Up
    11. Footer
*/

/*
    General
*/

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

input {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    outline: none;
}

.white-text {
    color: #FFFFFF;
}

.grey-text {
    color: #818181;
}

.blue-text {
    color: #3141C5;
}

.center-text {
    text-align: center;
}

h1, h2, h3, h4 {
    font-weight: 500;
}

h5, h6 {
    font-weight: 400;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 30px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.center {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: unset;
}

::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

strong {
    font-weight: 600;
}

button {
    outline: none;
    cursor: pointer;
}

/*
    Navbar
*/

#navbar-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 1;
}

.transparent-nav {
    color: #FFFFFF;
}

.dark-nav {
    background-color: #2B2B2B;
    color: #FFFFFF;
}

.navbar-logo {
    position: absolute;
    top: 20px;
    left: 20px;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
}

.navbar-content div {
    margin-right: 50px;
    cursor: default;
}

.navbar-content div:hover {
    color: #929292;
}

.menu-email {
    color: #f7ce0c;
    font-weight: 600;
}

.menu-email:hover {
    color: #f7ce0c !important;
}

/*
    Home
*/

.home-image {
    background: url("../images//home.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 85vh;
    display: flex;
    flex-direction: column;
}

.desc {
    width: 50%;
    text-align: center;
}

.scroll-down {
    background-color: #f7ce0c;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.scroll-down:hover {
    background-color: rgb(247,206,12);
}

.impact-areas {
    display: flex;
    flex-wrap: wrap;
    justify-self: center;
    top: 75%;
    margin-top: -8%;
}

.impact-area {
    width: 25%;
    height: 300px;
    text-align: center;
    padding: 3%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: rgb(251, 251, 251);
    overflow: hidden;
}

.impact-area h6 {
    color: #4A4A4A;
}

.home-sdg-goals {
    margin: 8%;
    line-height: 2em;
}

.home-sdg {
    margin-left: 2%;
    height: 275px;
}

.home-featured {
    margin-top: 3%;
}

.home-sample {
    margin-top: 5%;
    width: 80%;
}

.home-sample h4 {
    color: #8C9198;
}

.home-sample-img {
    position: relative;
    width: 45%;
    margin-right: 5%;
}

.home-sample:nth-last-of-type(1) {
    margin-bottom: 10%;
}

.home-sample-details {
    margin-bottom: 10px;
}

/*
    About
*/

#about-container {
    padding-top: 10%;
}

.Collapsible {
    background-color: white;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.Collapsible__contentInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 490px;
    text-align: center;
    font-size: 15px;
}
.Collapsible__contentInner img{
    margin-top: 1em;
    width: 400px;
}

.Collapsible__contentInner p:last-child {
    margin-bottom: 1px; 
}
  
.Collapsible__trigger {
    position: relative;
    display: flex;
    cursor: pointer;
    width: 500px;
    font-weight: 600;
    border: 1px solid rgb(199, 199, 199);
    padding: 12px;
    border-radius: 6px;
}

.Collapsible__trigger.is-closed {
    background: #FFF;
    color: #262626;
}

.Collapsible__trigger.is-open {
    background: #3141C5;
    border: 1px solid #FFF;
    color: #FFF;
}

.Collapsible__trigger:after {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    content: '\f107';
    right: 10px;
    top: 10px;
    transition: transform 300ms; 
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); 
    color: #FFF;
}

.Collapsible__trigger.is-closed:after { 
    color: rgb(199, 199, 199);
}

.about-desc {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 60px;
    line-height: 2em;
}

.get-in-touch-section {
    background: rgb(249, 249, 249);
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 70px;
}

#get-in-touch-heading {
    margin-bottom: 20px;
}

#get-in-touch-text {
    display: inline-block;
    width: 35%;
    font-size: 14px;
    margin-top: 0px;
}

#contact-us-btn {
    background-color:#f7ce0c;
    color: #FFF;
    display: inline-block;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: 12px 30px;
    font-weight: 700;
}

/* 
    Search
*/

#search-container {
    padding: 10% 5%;
}

.search-sample-img {
    width: 28%;
    margin-bottom: 4%;
    margin-right: 3%;
}

.search-input {
    border: 1px solid lightgray;
    border-radius: 8px 0 0 8px;
    width: 56%;
    padding: 25px;
    border-right-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-button {
    background-color: #3141C5;
    color: #FFFFFF;
    border-radius: 0 8px 8px 0;
    border: 1px solid #3141C5;
    padding: 25px;
    width: 110px;
}

.search-input:focus, .search-button:focus {
    outline: none;
}

.options-wrapper {
    position: absolute;
    right: 28%;
}

.search-options, .selected-option {
    border: 1px solid lightgray;
    padding: 25px;
    color: #666666;
    background-color: #FFFFFF;
}

.search-options > i, .selected-option > i {
    margin-left: 5px;
}

.search-options {
    border-top-style: none;
}

.search-options:first-child {
    border-top-style: solid;
}

.search-options:hover {
    background-color: rgb(248, 248, 248);
}

.selected-option { 
    border-right-style: none;
}

.create-new-btn {
    margin-top: 20px;
}

.search-results {
    margin-top: 70px;
}

.search-results h3 {
    margin: 0;
}

.result-details {
    margin-bottom: 10px;
}



/*
    Activity Detail
*/

#activity-detail-container {
    padding-top: 100px;
    display: grid;
    grid-template-columns: 33% auto 33%;
    grid-template-rows: 20em auto;
}

.activity-detail-header {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 100%;
    height: 20em;
    overflow: hidden;
}

.activity-detail-header-img{
    position: relative;
    width: 100%;
}

.activity-detail-right {
    padding: 5%;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    background-color: #f9f9f9;
}

.activity-detail-left {
    padding: 0 5%;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.btnRegister, .btnRegistered {
    border-radius: 6px;
    border: 0px;
    background-color: #f7ce0c;
    color: white;
    font-size: 14px;
    padding: 1em 2em;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.btnRegister:hover {
    background-color: rgb(247,206,12);
}

.activity-name {
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 5px;
}

.activity-detail-item{
    padding: 5% 1%;
}

.activity-subhead {
    font-size: 18px;
    font-weight: 500;
}

.activity-detail-item a {
    color: #3141C5;
    font-weight: 500;
    padding-top: 5%;
}

.activity-detail-left .activity-subhead {
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 5px;
}

/*
    My Activities
*/

#my-activities-container {
    padding-top: 10%;
}

.activities-tabs {
    margin: 5%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.activity-img{
    width: 28%;
    margin-bottom: 4%;
    margin-right: 3%;
}

.activity-title {
    margin: 0;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    width: 25em;
    border-radius: 100px;
    margin: 0 0 5%;
    padding: .5em;
    background-color: #F5F5F5;
}

.react-tabs__tab {
    display: inline-block;
    border-radius: 100px;
    position: relative;
    list-style: none;
    padding: 3% 5%;
    cursor: pointer;
    background-color: #F5F5F5;
}

.react-tabs__tab--selected {
    background-color: #FFFFFF;
    color: black;
    border-radius: 100px;
    font-weight: 500;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}
  

/* 
    Activity Detail - Modal
*/

.modal {
    padding: 7% 10%;
}

.modal-btn {
    justify-content: flex-end;
    margin-top: 10%;
}

.confirm-btn, .cancel-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    border-radius: 5px;
}

.confirm-btn:hover {
    background-color: rgb(247,206,12);
}

.cancel-btn:hover {
    border-color: rgb(247,206,12);
    color: rgb(247,206,12);
}

.confirm-btn {
    color: #FFFFFF;
    background-color: #f7ce0c;
    margin-right: 15px;
}

.cancel-btn {
    color: #f7ce0c;
    border: 2px solid #f7ce0c;
}

/*
    Create New Activity Form
*/

#create-container {
    padding-top: 150px;
    padding-bottom: 150px;
}

#sia_info {
    font-size: 12px;
    color: #3141C5;
}

#info_icon {
    font-size: 17px;
}

#img_info {
    font-size: 12px;
    font-weight: 400;
}

#externalLink {
    margin-right: 0%;
}

#create_btn {
    margin: 0 auto;
    float: right;
}

.create_activity_form {
    margin: 0 auto;
    padding-top: 2%;
    max-width: 60%;
}

.create_activity_form label {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    font-weight: 600;
    font-size: 18px;
}

.create_activity_form input, 
.create_activity_form select {
    margin-right: 18%;
    width: 50%;
}

.create_activity_form textarea,
#externalLink{
    width: 68%;
}

.create_activity_form input, 
.create_activity_form select,
.create_activity_form textarea {
    resize: none;
    border: 1px solid rgb(199, 199, 199);
    margin-bottom: 5px;
    margin-left: 10px;
    padding: 19px;
    border-radius: 7px;
    font-family: inherit;
}

#uploadContainer {
    border: 1.5px solid #3141C5;
    border-style: dashed;
    padding: 120px;
    background: url("../images//checkmark.png");
    background-repeat: no-repeat;
    background-position: 50% 10%;
}

.create_activity_form input:focus, 
.create_activity_form select:focus,
.create_activity_form textarea:focus {
    outline: none !important;
    border: 1px solid #3141C5;
}

.create_activity_form button {
    background-color: #3141C5;
    color: #FFF;
    display: inline-block;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: 15px 50px;
    font-weight: 700;
    margin-top: 50px;
}

.create_activity_form button:hover {
    cursor: pointer;
}

.validation_feedback {
    display: flex;
    justify-content: flex-end;
    color: rgb(235, 54, 54);
    font-size: 15px;
    margin-top: -29px;
    margin-bottom: 15px;
    margin-right: 18%;
}

#short_desc_vf,
#full_desc_vf,
#link_vf {
    margin-right: 0%;
}

input.highlight_error,
select.highlight_error,
textarea.highlight_error {
    border-color: rgb(235, 54, 54);
}

/*
    Authentication:
    Login and Sign Up
*/

.auth-container {
    padding: 10% 0;
    display: flex;
    flex-direction: column;
}

.auth-form {
    margin: auto;
}


.auth-form input {
    border: 1px solid rgb(199, 199, 199);
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 6px;
    width: 100%;
    font-family: inherit;
}

.auth-form label {
    display: flex;
    flex-wrap: column;
    font-weight: 600;
    font-size: 18px;
}
.auth-form input {
    width: 350px;
}

.auth-btn {
    padding: 1em;
    border-radius: 5px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    color: #FFFFFF;
    background-color: #f7ce0c;
    margin-top: 15px;
}

.auth-btn:hover{
    background-color: rgb(247,206,12);
}

.auth-validation {
    display: flex;
    justify-content: flex-end;
    color: rgb(235, 54, 54);
    font-size: 15px;
    margin: -15px 0 15px;
}

.login-error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(235, 54, 54);
    background-color: rgb(255, 236, 236);
    font-size: 15px;
    font-weight: 600;
    border: solid rgb(235, 54, 54) 1px;
    padding: 0.5em;
}
.login-error i {
    margin-right: 10px;
}

.other-auth {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.other-auth-link {
    font-weight: 500;
    text-decoration: underline #f7ce0c;
    color: #f7ce0c;
    margin-left: 5px;
}

/*
    Footer
*/

#footer-container {
    background: #262626;
    padding: 3% 5% 5% 5%;
}

#footer-container .column:first-child {
    margin-right: 5%;
}

.footer-desc {
    width: 50%;
    margin-left: 7%;
}

.footer-desc i {
    background: #3141C5;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.line {
    width: 95%;
    border: 0.5px solid #4e4e4e;
    margin: 5% 0 3%;
}